import { Form, useParams } from 'react-router-dom';
import { Button } from 'antd';

export const ContactPage = () => {
    const { contactId } = useParams();
    return (
        <div id="contact">
            <Button>I`m a contact {contactId}</Button>
        </div>
    );
};
