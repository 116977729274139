import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { IndexPage } from './pages/index-page';
import { ContactPage } from './pages/_contactId/index';
import { ErrorPage } from './pages/error-page';
import { DefaultLayout } from './layouts/default';
import { LoginPage } from './pages/login-page';

import { RequireAuth } from './components/hoc/RoquireAuth';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <DefaultLayout />
                        </RequireAuth>
                    }
                >
                    <Route
                        index
                        element={<IndexPage />}
                        errorElement={<ErrorPage />}
                    />

                    <Route
                        path="contacts/:contactId"
                        element={<ContactPage />}
                        errorElement={<ErrorPage />}
                    />
                </Route>
                <Route
                    path="/login"
                    element={<DefaultLayout />}
                >
                    <Route
                        index
                        element={<LoginPage />}
                        errorElement={<ErrorPage />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);
