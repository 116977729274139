import { useLocation, Navigate } from 'react-router-dom';

export const RequireAuth = (props?: { children: JSX.Element }) => {
    const location = useLocation();
    const auth = true;

    if (!auth)
        return (
            <Navigate
                to="./login"
                state={{ from: location }}
            />
        );

    return props?.children || <></>;
};
